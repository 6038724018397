/*Lindyfest website yellow color:  #c29f47*/
/*Dark yellow color:  #C39E43*/
/*Yellow color:  #E4B950*/
/*Dark gray color:  #3E3C3D*/
/*Gray color:  #939598*/

/******************************GENERAL***************************************/

/* Center the avatar image inside this container */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

/* Avatar image */
img.avatar {
  width: 40%;
  border-radius: 50%;
}

/* Add padding to containers */
.container {
  padding: 8px 16px;
}

.body-container {
  padding: 12px;
}

body {
  margin: 0;
  font-family: 'Segoe UI', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;*/
  font-family: 'Segoe UI', sans-serif !important;
}

.btn.btn-primary {
  color: white;
  --bs-btn-bg: #3E3C3D;
  --bs-btn-border-color: #3E3C3D;
  --bs-btn-hover-bg: #3E3C3D;
  --bs-btn-hover-border-color: #3E3C3D;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3E3C3D;
  --bs-btn-active-border-color: #3E3C3D;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  font-weight: 475;
}

.pageTitle {
  background-color: #3E3C3D;
  color: white;
  padding: 15px;
}

.hide{
  display: none;
}

.show{
  display: block;
}

.dancer_here {
  background-color: #B6DA9F !important;
}
.dancer_scratch {
  background-color: #FF9A98 !important;  
}
/******************************NAVIGATION***************************************/
#root .navbar {
  background-color: #c39e43;
  padding: 5px 15px;
}

#root .navbar .container {
  margin-left: inherit;
}

#root .navbar .nav-link {
  color: white;
  font-weight: 600;
}

#root .navbar .nav-link.active {
  color: #E4B950;
}

#root .navbar-brand .navbar-logo {
  max-height: 50px;
}

#root .navbar-brand {
  margin: auto !important;
}

#root .navbar-brand .brand-text {
  color: white;
  font-weight: 600;
  font-size: 22px;
  padding-right: 20px;
}

.basic-navbar-nav {
  margin: auto;
}

.me-auto {
  margin: auto;
}

/* menu toggle at 990px width*/
@media screen and (max-width: 990px) {
  #root .navbar .container {
    padding: 0px;
  }

  #root .navbar-nav li.brand-item {
    position: absolute;
    right: 0px;
  }

  #root .navbar-nav li.nav-item:not(.signIn-li) {
    margin: 25px 10px 0px 1px;
  }

  #root .navbar-brand .brand-text {
    display: none;
  }

  #root .navbar-brand .navbar-logo {
    max-height: 50px;
  }

  .navbar-toggler {
    position: absolute;
    top: 32px;
    right: 4px;
    border-width: 0px !important;
    color: white;
  }

  .navbar-toggler .navbar-toggler-icon {
    width: 25px;
    height: 25px;
    color: white;
  }

  .me-auto {
    padding-right: 0px;
  }
}

/******************************SIGN/OUT***************************************/
.signIn-li {
  position: absolute;
  right: 15px;
  color: white;
}

.signIn-li p {
  margin-bottom: 0px;
  padding-top: 8px;
}

.signIn-li a.sign-out {
  color: white;
  font-Size: 16px;
  padding: 12px 0px;
  font-weight: 600;
  text-decoration: underline;
}

@media screen and (max-width: 990px) {
  .signIn-li {
    position: inherit;
  }
}
/******************************HOME VIEW***************************************/
.HomePlacementsContainer {
  padding: 8px 16px;
}
.HeatInfo,.disputeInfo,.BadgeInfo,.HeatSessionInfo{
  padding-top: 16px;
  padding-left: 16px;
}
/******************************BADGE CREATE VIEW***************************************/
.btn.badge-create {
  padding: 6px;
  background-color: #c39e43;
  border-color: #c39e43 !important;
  border-width: 4px;
  font-weight: 600;
}

#final-search-data .p-datatable .p-datatable-tbody > tr > td{
  padding: 6px 12px;
}

.dancer_emailed{  
  background-color: #02800d99 !important;
  border-color: #02800d !important;
}
.btn.badge-create.hide{
  display: none;
}

.BadgeCreatePlacementsContainer .searchResultsContainer {
  max-height: unset;
}

.BadgeCreatePlacementsContainer .searchResultsContainer .p-datatable-flex-scrollable {
  max-height: unset;
}

.BadgeCreatePlacementsContainer .p-datatable .p-datatable-tbody > tr > td {
  padding: 4px;
}
/******************************CHECK-IN VIEW***************************************/
.sendHeatEmailBtn{  
  margin-left: 12px;
  margin-top: 8px;
  font-weight: 600;
  padding: 4px;
  border-width: 2px;
  background-color: #c39e43 !important;
  border-color: #c39e43 !important;
  color: black !important;
}

#emailResponseMessage{
  padding: 4px 12px;
  color: white;
}

#checkinTable .p-datatable-flex-scrollable {
  max-height: calc(100vh - 215px);
}
button.checkin-button,
button.checkin-button:hover,
button.checkin-button:focus,
button.checkin-button:focus-within,
button.checkin-button:focus-visible {
  margin-right: 4px;
  font-weight: 600;
  color: #FFFFFF;
  border-width: 2px;
}

.dancer-here{
  background-color: #02800d !important;
  border-color: #02800d !important;
}

.dancer-move{
  background-color: #022480 !important;
  border-color: #022480 !important;
}

.dancer-scratch{
  background-color: #ec1010 !important;
  border-color: #ec1010 !important;
}
/******************************HEAT VIEW***************************************/
.heat {
  background-color: #3E3C3D;
  padding: 10px 15px;
  font-weight: 600;
  font-size: 20px;
}

.heat #tabelLabel {
  font-size: 30px;
  color: white;
}

.Dropdown-root {
  width: max-content;
  display: inline-block;
  padding-left: 20px;
}

.Dropdown-root .Dropdown-control {
  width: max-content;
  height: fit-content;
  display: inline-flex;
  padding: 5px 42px 5px 8px;
}

.heat .heatLink {
  color: #939598;
  text-decoration: none;
  padding: 0 10px;
}

.heat .heatLink.active {
  color: #c39e43;
  text-decoration: underline;
}

#tableContainer .p-datatable .p-datatable-thead>tr>th,
.searchResultsContainer .p-datatable .p-datatable-thead>tr>th {
  padding: 5px 0px;
  text-align: center;
}

#tableContainer .p-datatable .p-sortable-column .p-sortable-column-icon,
.searchResultsContainer .p-datatable .p-sortable-column .p-sortable-column-icon {
  margin-left: 0px;
}

#tableContainer .p-datatable .p-datatable-tbody>tr>td,
.searchResultsContainer .p-datatable .p-datatable-tbody>tr>td {
  white-space: normal;
  text-align: center;
}

#heattable .p-datatable-flex-scrollable {
  max-height: calc(100vh - 165px);
}

.heatContainer .p-datatable .p-datatable-thead>tr>th {
  text-align: center;
}

.heatContainer .p-datatable .p-datatable-tbody>tr>td {
  text-align: center;
}

#heatTableContainer .p-datatable .p-datatable-thead > tr > th {
  padding: 8px 8px;
}

#heatTableContainer .p-datatable .p-sortable-column .p-sortable-column-icon{
  margin-left: 0px;
}

#heatTableContainer .p-datatable .p-datatable-tbody > tr > td{
padding: 8px 8px;
}

span.p-column-title {
  width: 100%;
}

button.placement-button,
button.placement-button:hover,
button.placement-button:focus,
button.placement-button:focus-within,
button.placement-button:focus-visible {
  margin-right: 4px;
  margin-bottom: 4px;
  font-weight: 600;
  color: #939598;
  background-color: #FFFFFF;
  border-color: #939598;
  border-width: 2px;
}

button.default,
button.default:hover,
button.default:focus,
button.default:focus-within,
button.default:focus-visible {
  background-color: #3E3C3D;
  border-color: #3E3C3D;
  color: white;
  border-width: 2px;
}

button.final,
button.final.default {
  background-color: #c39e43 !important;
  border-color: #c39e43 !important;
  color: #FFFFFF !important;
}

button.clear {
  background-color: #808080 !important;
  border-color: #808080 !important;
  border-width: 4px;
  color: #FFFFFF !important;
  padding: 4px 18px;
}
button.scratch {
  background-color: #9e2d00 !important;
  border-color: #9e2d00 !important;
  color: #FFFFFF !important;
}
/* menu toggle at 990px width*/

@media screen and (max-width: 990px) {

  .p-datatable .p-datatable-tbody>tr>td,
  .p-datatable .p-datatable-thead>tr>th {
    padding: 4px 4px;
  }

  .heatContainer .p-datatable .p-datatable-thead>tr>th {
    font-size: 14px;
    padding: 2px;
  }

  .heat #tabelLabel {
    font-size: 20px;
  }
}

/******************************ADMIN VIEW***************************************/
.adminButtonContainer button {
  margin: 10px;
}

.adminButton {
  margin: 10px;
}

.adminContainer .p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: #9395985e !important;
  color: #3E3C3D;
}

.mainContainer .p-accordion .p-accordion-header .p-accordion-header-link,
.mainContainer .p-accordion .p-accordion-header .p-accordion-header-link {
  border-radius: 0px !important;
}

.resultMessage {
  margin-left: 15px;
}

/***********BIB SEARCH***********/
#dancerDetail {
  padding-top: 15px;
}

.adminInputLabel {
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
}

.p-inputtext {
  padding: 7px 12px !important;
  margin-right: 15px;
}

.dancerData {
  font-size: 16px;
  padding: 15px;
}

.dancerData label {
  font-weight: 500;
}

.dancerData .bibData {
  padding-left: 15px;
}

.searchResultsContainer {
  max-height: 500px;
}

.searchResultsContainer .p-datatable-flex-scrollable {
  max-height: 500px;
}

#trackBalanceDetail .searchResultsContainer .p-datatable-flex-scrollable {
  max-width: 500px;
}

.spinner-parent {
  position: absolute;
  height: calc(100% - 85px);
  width: 100%;
  top: 85px;
  background-color: #9395985e;
}

.spinner-parent .spinner-container {
  margin: auto;
  display: flex;
  height: 100%;
  padding-top: calc(50vh - 132px);
}